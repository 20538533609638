.photoProcessingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  top: 0;
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: auto;
  h3 {
    font-size: 16px;
  }
  img {
    display: block;
    max-width: 280px;
  }
}
