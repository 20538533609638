@import "../../global.scss";

.popupWrapper {
  border-radius: 10px;
  padding: 25px;
  background-color: $white;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 400px;
  margin: 0 auto;
  z-index: 5;

  .closePopup {
    position: absolute;
    right: 25px;
    top: 27px;
    cursor: pointer;
    img {
      display: block;
      max-width: 24px;
    }
  }
  h3 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  .popupContent {
    p,
    h4,
    strong {
      display: block;
      margin-bottom: 15px;
      font-family: $font-default;
    }
    p,
    strong {
      font-size: 12px;
      line-height: 18px;
    }
    .customParagraph {
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .acceptTerms {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dbdbdb;
    label {
      display: flex;
      align-items: flex-start;
      font-size: 12px;
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: $white;
      margin: 3px 30px 0 0;
      position: relative;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        border-radius: 2px;
        border: 1px solid $color-default;
      }
      &:checked::before {
        background: url("../../resources/images/checkbox.svg") no-repeat center
          center;
      }
    }
  }
  &.popupCenter {
    top: 50vh;
    transform: translateY(-50%);
    position: fixed;
    left: 25px;
    right: 25px;
  }
  &.popupBottom {
    height: auto;
    max-width: 450px;
    overflow: auto;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.acceptTermsBtn[type="file"] {
  &::before {
    content: "continue";
  }
}
.hideDiv {
  display: none;
}
