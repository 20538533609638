@import "../../global.scss";

.btnDefault,
input[type="file"] {
  position: relative;
  border: 2px solid $color-default;
  padding: 20px 30px;
  margin: 0 auto 20px;
  text-align: center;
  border-radius: 50px;
  font-family: $font-headingBold;
  font-size: 16px;
  line-height: 16px;
  color: $color-default;
  background-color: transparent;
  border-color: $color-default;
  max-width: 295px;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  appearance: none;

  cursor: pointer;
  .btnDefault:last-of-type {
    margin-bottom: 0;
  }
  &.btnYellow {
    background-color: $yellow;
    border-color: $yellow;
  }
  &.btnHollow {
  }
  &.btnGray {
    background-color: $dark-gray;
    border-color: transparent;
    color: $white;
  }
  @media all and (max-width: $screen-sm-mobile) {
    padding: 20px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

input[type="file"] {
  font-size: 0;
  height: 60px;
  &::-webkit-file-upload-button,
  &::-webkit-appearance {
    visibility: hidden;
  }
  &:disabled {
    background-color: $dark-gray;
    color: $white;
    cursor: no-drop;
  }
  &::before {
    content: "choose a photo";
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 16px;
  }
}
