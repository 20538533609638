.cropper-view-box,
.cropper-face {
  border-radius: 100%;
  outline-color: transparent;
}

.cropper-container {
  height: 100%;
  margin: 0 auto;
}
