@import "../../global.scss";
.SuccessMessageWrapper {
  padding: 25px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 450px;
  height: 100vh;
  margin: 0 auto;
  background-color: $light-gray;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
  position: relative;
  overflow: hidden;
}
.uploadWrapper {
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #fff;
  width: calc(100% - 50px);
  margin: 0 auto;
  @media all and (max-width: 375px) {
    padding: 30px 15px;
    width: calc(100% - 30px);
  }
  p {
    margin-top: 20px;
  }
  img {
    display: block;
    margin: 0 auto 20px;
    max-width: 120px;
  }
}
.logo {
  img {
    display: block;
    margin: 0 auto 20px;
  }
}

.copyRights {
  background-color: $color-default;
  padding: 10px 25px;
  margin: 40px 0 0;
  p {
    font-size: 12px;
    line-height: 18px;
    color: $white;
    text-align: center;
  }
}
