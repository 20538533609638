// Fonts

$font-default: "AllTogetherSans";
$font-headingRegular: "AllTogetherSerif-Regular";
$font-headingBold: "AllTogetherSerif-Bold";

@font-face {
  font-family: $font-default;
  src: url("resources/fonts/AllTogetherSans-Regular.ttf"),
    url("resources/fonts/AllTogetherSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: $font-headingRegular;
  src: url("resources/fonts/AllTogetherSerif-Cn.ttf"),
    url("resources/fonts/AllTogetherSerif-Cn.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: $font-headingBold;
  src: url("resources/fonts/AllTogetherSerif-CnBold.ttf"),
    url("resources/fonts/AllTogetherSerif-CnBold.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

// Colors

$white: #fff;
$black: #000;
$black-light: #040607;
$gray: #68717d;
$light-gray: #f3f6fa;
$dark-gray: #b9c1ca;
$brown: #5a1f06;
$yellow: #ffd200;
$yellow-light: #fff2b6;
$green: #00a832;
$light-green: #caffda;
$orange: #fa6400;
$blue: #0e74e1;

$color-default: #5a1f06;

// Font Family and Weight

$font-300: 300;
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-800: 800;

// Container

$container-width: 1920px;

// Gutter

$gutter-width: 25px;

// Media Breakpoints

$screen-xxl: $container-width;
$screen-xl: 1600px;
$screen-lg: 1366px;
$screen-md: 1199px;
$screen-tablet: 991px;
$screen-mobile: 767px;
$screen-sm-mobile: 575px;

.container {
  max-width: $screen-xxl;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  margin: 0 auto;

  @media all and (max-width: $screen-xl) {
    max-width: $screen-xl;
  }
  @media all and (max-width: $screen-lg) {
    max-width: $screen-lg;
  }
  @media all and (max-width: $screen-md) {
    max-width: $screen-md;
  }
  @media all and (max-width: $screen-tablet) {
    max-width: $screen-tablet;
  }
  @media all and (max-width: $screen-mobile) {
    max-width: $screen-mobile;
    padding-left: calc($gutter-width - 10px);
    padding-right: calc($gutter-width - 10px);
  }
}

// Reset styles

body {
  font-family: $font-default;
  -webkit-touch-callout: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $font-headingBold;
  line-height: 30px;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 15px;
  line-height: 26px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

input,
select {
  &:focus {
    outline: none;
  }
}

// Padding Classes

// bottom padding

.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}

// top padding

.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}

// Horizontal and vertical padding

.px15 {
  padding: 0 15px;
}
.px20 {
  padding: 0 20px;
}
.px30 {
  padding: 0 30px;
}
.py15 {
  padding: 15px 0;
}
.py20 {
  padding: 20px 0;
}
.py30 {
  padding: 30px 0;
}

// Margin Classes

// bottom margin

.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}

// top margin

.mt-0 {
  margin-top: 0;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}

// Horizontal and vertical margin

.mx15 {
  margin: 0 15px;
}
.mx20 {
  margin: 0 20px;
}
.mx30 {
  margin: 0 30px;
}
.mxauto {
  margin: 0 auto;
}
.my15 {
  margin: 15px 0;
}
.my20 {
  margin: 20px 0;
}
.my30 {
  margin: 30px 0;
}
