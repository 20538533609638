@import "../../global.scss";

.sectionHeading {
  h1 {
    font-size: 36px;
    color: $color-default;
    @media all and (max-width: $screen-mobile) {
      font-size: 30px;
    }
  }
  h2 {
    font-size: 30px;
    color: $color-default;
    @media all and (max-width: $screen-mobile) {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 24px;
    color: $color-default;
  }
  p {
    font-size: 16px;
    color: $black-light;
    margin-top: 15px;
    font-family: $font-default;
    font-weight: 400;
  }
}

.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
