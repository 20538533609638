@import "../../global.scss";

.imageUploadModule {
  .uploadPhotoContainer {
    position: relative;
    height: 50vh;
    max-height: 500px;
    display: flex;
    background-color: rgba(230, 230, 230, 1);
    > div {
      width: 100%;
      height: 100%;
    }
    img {
      filter: grayscale(1);
    }
  }

  .cropContainer {
    height: 100vh;
    max-height: 550px;
    img {
      filter: grayscale(1);
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    label {
      width: 100%;
      max-width: 320px;
      box-sizing: border-box;
      position: relative;
      top: 2px;
      padding: 0 15px;
      .range {
        color: $color-default;
        padding: 15px 0;
        height: 8px;
        > span:first-of-type {
          background-color: $dark-gray;
        }
        > span:last-of-type {
          border: 5px solid $color-default;
          background-color: $white;
          height: 24px;
          width: 24px;
        }
      }
    }
    img {
      cursor: pointer;
      padding: 10px;
      max-width: 50px;
    }
  }

  .editImageWrapper {
    padding: 25px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: $white;
    .removeBgMsg {
      border-radius: 10px;
      background-color: $yellow-light;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 5px 5px 15px;
      margin: 20px 0;
      p {
        font-size: 12px;
        line-height: 16px;
        font-family: $font-headingBold;
        max-width: 215px;
        margin-top: 0;
      }
      img {
        display: block;
        max-width: 100px;
      }
    }
    .resetImage {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      cursor: pointer;
      img {
        display: block;
        max-width: 24px;
        margin-right: 10px;
      }
      p {
        font-size: 14px;
        font-weight: $font-600;
        margin: 0;
      }
    }
  }
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    border-top: 1px solid #dbdbdb;
    margin: 0 -25px;
    button {
      max-width: 150px;
      margin: 0 5px;
    }
  }
}

.showPopup {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(4, 6, 7, 0.75);
    z-index: 1;
  }
}
