@import "../../global.scss";

.uploadPhotoWrapper {
  padding: 25px 0 0;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin: 0 auto;
  background-color: $light-gray;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
  position: relative;
  overflow: hidden;

  &.showPopup {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(4, 6, 7, 0.75);
      z-index: 1;
    }
  }
}
.uploadWrapper {
  padding: 30px 20px;
  border-radius: 10px;
  background-color: $white;
  width: calc(100% - 50px);
  margin: 0 auto;
  @media all and (max-width: 375px) {
    padding: 30px 15px;
    width: calc(100% - 30px);
  }
}
.logo {
  img {
    display: block;
    margin: 0 auto 30px;
  }
}

.btnWrapper {
  margin-top: 30px;
  text-align: center;
  button {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.tipsWrapper {
  margin-top: 40px;
  padding: 0 25px;
  @media all and (max-width: 375px) {
    padding: 0 15px;
  }
  .tipsImgWrapper {
    margin: 25px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    .tipsImageContainer {
      text-align: center;
      img {
        display: block;
        margin: 0 auto 10px;
        max-width: 88px;
        &.tick {
          max-width: 24px;
        }
      }
      p {
        font-size: 12px;
        max-width: 85px;
        margin: 0 auto;
        line-height: 18px;
      }
      @media all and (max-width: 375px) {
        min-width: 85px;
        img {
          max-width: 80px;
        }
      }
    }
  }
  .tipsListWrapper {
    ul {
      list-style: none;
      padding: 0 0 0 15px;
      margin: 0;
      li {
        margin-bottom: 5px;
        &::before {
          content: "\2022";
          color: $yellow;
          font-weight: bold;
          display: inline-block;
          width: 15px;
          margin-left: -15px;
          font-size: 30px;
          position: relative;
          top: 4px;
        }
      }
    }
    p,
    li {
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.imageResizer {
  background-color: $white;
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 10%);
}

.copyRights {
  background-color: $color-default;
  padding: 10px 25px;
  margin: 40px 0 0;
  p {
    font-size: 12px;
    line-height: 18px;
    color: $white;
    text-align: center;
  }
}

.choosePhoto {
  &::after {
    position: absolute;
    content: "choose a photo";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $yellow;
    border-color: $yellow;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50px;
  }
}
.mobileCapture[type="file"] {
  &::before {
    content: "take a photo";
  }
  &::after {
    position: absolute;
    content: "take a photo";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-color: $color-default;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 50px;
  }
}
